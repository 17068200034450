<script lang="ts">
  import type { Policy } from "../../types";
  import FileDocument from "../file/FileDocument.svelte";
  import Info from "../util/Info.svelte";
  import Warning from "../util/Warning.svelte";
  import PolicySummary from "./PolicySummary.svelte";

  export let policy: Policy = null;
</script>

{#if policy}
  <article class="policy">
    <PolicySummary {policy} />
    {#if policy.alert?.text}
      <Warning message={policy.alert.text} />
    {/if}
    {#if policy.info?.text}
      <Info message={policy.info.text} />
    {/if}
    <ul class="files">
      {#each Object.values(policy.documents?.items ?? {}) as document}
        <li>
          <FileDocument {document} />
        </li>
      {/each}
    </ul>
  </article>
{/if}
