<script lang="ts">
  import { route } from "../../util/router";
  import InfoTagScanner from "./InfoTagScanner.svelte";

  // handle media scans or route urls
  function oninfotag({ detail: value }) {
    console.log("infotag=", value);
    if ("media" === value?.type) return route("/media/" + value.id);
    //if(value.format)
    if (value?.url) window.location.href = value.url;
  }
</script>

<InfoTagScanner on:infotag={oninfotag} />
