<script>
  import Camera from "./Camera.svelte";

  import { createEventDispatcher, onMount } from "svelte";

  export let selected = null;

  const dispatch = createEventDispatcher();

  let preview;
  let cam;

  //$: cam?.scrollIntoView();

  onMount(() => {
    return clearPreview;
  });

  function clearPreview() {
    setPreview(null);
  }

  function setPreview(fileOrBlob) {
    if (preview) {
      URL.revokeObjectURL(preview);
    }
    preview = fileOrBlob && URL.createObjectURL(fileOrBlob);
    //console.log("preview=", preview);
  }

  function select(value) {
    selected = value;
    if (value) dispatch("url", selected);
    clearPreview();
  }

  async function delayClearPreview(sec) {
    await new Promise((r) => setTimeout(r, sec * 1000));
    clearPreview();
  }

  let last;

  async function onbarcode(e) {
    // If we're already processing, don't process more than one at a time.
    if (preview) return;

    //console.log("onbarcode=", e);

    const { barcode, blob } = e.detail;

    const photo = blob;

    if (!photo || !barcode || barcode == last) clearPreview();

    if (!barcode) return;
    if (barcode === last) return; // no new barcode

    //console.log("onbarcode=", barcode, blob);

    try {
      new URL(barcode);
    } catch {
      // not a url
      return clearPreview();
    }

    setPreview(photo);

    last = barcode;

    select(barcode);
  }
</script>

<nav class="scanner">
  <Camera
    bind:this={cam}
    class="smart-decal"
    barcode={true}
    cover={preview}
    upload={false}
    on:barcode={onbarcode}
  />
  <slot />
</nav>
