<script lang="ts">
  import type { Media, Permits, Policies } from "../types";
  import MediaRecord from "./MediaRecord.svelte";
  import MediaStatusHeader from "./MediaStatusHeader.svelte";
  import FileDocument from "./file/FileDocument.svelte";
  import Policy from "./policy/Policy.svelte";
  import Help from "./util/Help.svelte";
  import PropertyHeader from "./util/PropertyHeader.svelte";
  import RecordItem from "./util/RecordItem.svelte";

  export let media: Media;
  export let permits: Permits;
  export let policies: Policies;
  const property = media.property;
  const active = Object.values(permits.items).filter((p) => !p.valid.expired);
  console.log("active=", active);
</script>

<main class="vehicle media record">
  <MediaStatusHeader {media} {permits} />
  {#if property}
    <PropertyHeader {property} />
  {/if}
  <MediaRecord {media} />
  <section class="permits">
    <ul>
      {#each Object.values(active) as permit}
        <li>
          <dl>
            {#if permit.tenant}
              <dt>Unit</dt>
              <dd><RecordItem item={permit.tenant} /></dd>
            {/if}
            {#if permit.name}
              <dt>Name</dt>
              <dd>{permit.name || ""}</dd>
            {/if}
          </dl>
        </li>
      {/each}
    </ul>
  </section>
  <section class="policies">
    {#if policies.count}
      <ul class="policies">
        {#each Object.values(policies.items) as policy}
          <li>
            <Policy {policy} />
          </li>
          <!-- {#each Object.values(policy.documents?.items ?? {}) as document}
            <li>
              <FileDocument {document} />
            </li>
          {/each} -->
        {/each}
      </ul>
    {:else}
      <Help
        message="Please contact your property management with questions, concerns, or changes"
      />
    {/if}
  </section>
</main>
