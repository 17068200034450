//import page from "page";
import { router, end as routed } from "./util/router";
import "./styles/app.scss";
import App from "./App.svelte";

const app = new App({ target: document.body, props: {} });

router(
  "/media/activate",
  function () {
    window.location.href = "https://parkingattendant.com/smartdecal";
  },
  routed
);

router(
  "/media/:media",
  function (ctx, next) {
    console.log("media route", ctx);
    next();
  },
  routed
);

router("/:view", routed);

// catch
router(
  "/",
  function (ctx, next) {
    var query = new URLSearchParams(ctx.querystring);
    var media =
      query.get("media") || query.get("m") || query.get("id") || query.get("d");
    if (!!media) return router.redirect("/media/" + media);
    //return router("/media/activate");
    next();
  },
  routed
);

router.start(); // start
