<script lang="ts">
  import type { Media } from "../types";
  import RecordItem from "./util/RecordItem.svelte";

  export let media: Media;
</script>

<figure class="media record">
  <figcaption>
    <h1>
      {media.label}
      {media.title}
    </h1>
  </figcaption>
  <RecordItem item={media} />
</figure>
