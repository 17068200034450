<script lang="ts">
  import type { Policy } from "../../types";

  export let policy: Policy = null;
</script>

{#if policy}
  <aside class="policy">
    <header>
      <h1>{policy.title}</h1>
    </header>
  </aside>
{/if}
