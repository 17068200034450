<script lang="ts">
  import type { Permitted, Media } from "../types";

  export let media: Media;
  export let permits: Permitted;

  const status = permits.expired
    ? "revoked"
    : permits.pending
    ? "pending"
    : permits.active
    ? "active"
    : "pending";

  const colors = {
    revoked: "#DF3A25",
    pending: "#DF3A25", // red 50
    active: "#21AA5A", // green 60
  };
  const titles = {
    revoked: "Revoked",
    pending: "Inactive",
    active: "Active",
  };
  const color = colors[status];
  const title = titles[status];
</script>

<svelte:head>
  <meta name="theme-color" content={color} />
</svelte:head>
<header>
  <h1>
    <data style="background-color:{color};" class="permitted" value={status}
      >{title}</data
    >
  </h1>
  <slot />
</header>
