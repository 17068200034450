<script lang="ts">
  import type { Media } from "../../types";
  import VehicleMedia from "../VehicleMedia.svelte";
  import PersonalMedia from "../PersonalMedia.svelte";
  import { mediaPermits, mediaPolicies } from "../../util/recordstores";
  export let media: Media;
  let property = media.property;

  console.log("media", media);

  const types = {
    vehicle: VehicleMedia,
    personal: PersonalMedia,
  };

  document.documentElement.setAttribute("data-usage", media.usage);

  //   function json(response) {
  //     if (!response) return {};
  //     return response
  //       .text()
  //       .then(function (text) {
  //         if (!text)
  //           return {
  //             status: response.status,
  //           };

  //         return Promise.resolve(text)
  //           .then(JSON.parse)
  //           .catch(function (error) {
  //             return {
  //               status: response.status,
  //               message: text,
  //             };
  //           });
  //       })
  //       .catch(function (error) {
  //         return {
  //           status: response.status,
  //         };
  //       });
  //   }

  //   async function apiFetch(method, url, body, auth) {
  //     // simple process
  //     //var requested = new Date().toISOString();
  //     auth = await auth;

  //     return Promise.all([method, url, body, auth])
  //       .then(function ([method, url, body, auth]) {
  //         var options = {};
  //         if (!!method) set(options, "method", method);
  //         //if(!!auth) set(options, "headers.Authorization", auth);
  //         if (!!auth)
  //           url =
  //             url + (url.indexOf("?") >= 0 ? "&" : "?") + "Authorization=" + auth;
  //         if (!!body) set(options, "body", body);

  //         return fetch(url, options);
  //       })
  //       .then(function (response) {
  //         //if(!!response.ok) return response.json();
  //         if (!!response.ok) return json(response);

  //         var e = Object.assign(new Error(response.status + ""), {
  //           name: response.status + "",
  //           status: response.status,
  //           response: response,
  //         });

  //         //_.extend(e, response);
  //         //set(e, "response", response);

  //         // return response.json()
  //         // .catch(function(e) {
  //         //     return null;
  //         // })
  //         return json(response).then(function (json) {
  //           //console.log("error json", e, json);
  //           if (!!json) set(e, "data", json);
  //           set(e, "message", get(json, "message") || e.message); // update message
  //           //console.log("error data", get(e, "data"));
  //           return Promise.reject(e);
  //         });
  //       })
  //       .catch(function (error) {
  //         // this is to catch a bug in Edge fetch
  //         //console.log("catching");
  //         //console.log("typemismatcherror", error);

  //         if (get(error, "name", error) === "TypeMismatchError")
  //           return Promise.reject(
  //             Object.assign(new Error("401"), {
  //               name: "401",
  //               status: 401,
  //             })
  //           );
  //         return Promise.reject(error);
  //       })
  //       .catch(function (error) {
  //         if (!!error && error.status == 401) {
  //           document.documentElement.dispatchEvent(
  //             new CustomEvent("unauthorized", { bubbles: true })
  //           );
  //           return Promise.resolve({ status: error.status });
  //         }
  //         return Promise.reject(error);
  //       });
  //   }

  //   async function getData(id) {
  //     //console.log(id);

  //     var auth = null;

  //     var requested = new Date().toISOString();
  //     return apiFetch(
  //       "GET",
  //       Promise.all([base(), id]).then(function ([base, id]) {
  //         return (
  //           base +
  //           "v1/media/" +
  //           id +
  //           "?valid=" +
  //           format(Date.now() - 1000 * 60 * 60, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") +
  //           "/" +
  //           format(Date.now() + 1000 * 60 * 60, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") +
  //           "&user=true"
  //         );
  //       }),
  //       null,
  //       auth ? auth.type + " " + auth.token : null
  //     )
  //       .then(function (json) {
  //         //console.log(json);

  //         json.requested = requested;

  //         var item = get(json, "media.item");
  //         item = get(json, ["media", "items", item], item);

  //         //if(!item) return json;

  //         // admin urls are no longer being returned in any capacity
  //         var location = get(json, [
  //           "locations",
  //           "items",
  //           get(json, "locations.item"),
  //         ]);
  //         if (!!location)
  //           location.admin = get(json, "admin.url", json.admin || location.admin);

  //         var user = get(json, "users.item");
  //         if (!!user) user = get(json, ["users", "items", user], user);

  //         var admin = null;
  //         if (!!location)
  //           admin = get(json, "admin.url", json.admin || location.admin);

  //         if (!!admin)
  //           admin = admin.substr(0, admin.lastIndexOf("/")) + "/media/" + item.id;

  //         // we know token works...shortcut
  //         if (!!location && !admin && !!user)
  //           admin =
  //             "https://fieldagent.parkingboss.com/" +
  //             location.id +
  //             "/media/" +
  //             item.id +
  //             "?access_token=" +
  //             auth.token; //admin = "https://my.parkingboss.com/users/" + user.id + "/navigate?url=https://fieldagent.parkingboss.com/" + location.id + "/";

  //         // break admin early...
  //         if (!!admin) {
  //           return {
  //             requested: json.requested,
  //             generated: json.generated,
  //             admin: admin,
  //           };
  //         }

  //         var tenantType = get(json, "tenants.type");

  //         //var location = get(json, ["locations", "items", get(json, "locations.item")]);
  //         //var address = get(json, ["addresses", "items", location.address], location.address);

  //         var permits = get(json, "permits.items");

  //         var users = get(json, "users.items");
  //         var vehicles = get(json, "vehicles.items");
  //         var tenants = get(json, "tenants.items", []);
  //         var spaces = get(json, "spaces.items");
  //         var notes = json.notes;

  //         var attendant = get(json, "attendants.item");
  //         if (!!attendant)
  //           attendant = get(json, ["attendant", "items", attendant], attendant);
  //         if (!attendant) attendant = get(json, "policies.count", 0) > 0;

  //         if (!!permits)
  //           permits = map(permits, function (item) {
  //             return item;
  //           });

  //         var valid = filter(permits, function (item) {
  //           return item.expired !== true;
  //         });

  //         return {
  //           type: "media",
  //           requested: json.requested,
  //           generated: json.generated,
  //           attendant: attendant,
  //           //admin: admin,
  //           item: item,
  //           location: location,
  //           tenants: json.tenants,
  //           user: user,
  //           permits: permits,
  //           activated: permits.length > 0,
  //           revoked: permits.length > 0 && valid.length == 0,
  //           valid: valid,
  //         };
  //       })
  //       .catch(function (error) {
  //         //if(error.status === 404) alert("Whoops, not found!");
  //         //else alert("Whoops, something went wrong!");
  //         console.log("catch", error);
  //         return Promise.reject(error);
  //       });
  //   }

  //   const _header = template(
  //     '<header><h1><%=  _.get(record, "valid.length", 0) > 0 ? "Active " : "" %><%=  _.get(record, "item.title") || "Smart Decal" %><%=  _.get(record, "valid.length", 0) > 0 ? "" :  _.get(record, "permits.length", 0) > 0 ? " Revoked" : " Unactivated" %></h1></header>',
  //     {
  //       variable: "record",
  //       imports: {},
  //     }
  //   );

  //   const _template = template(
  //     '<dl><dt class="media"><%=  _.get(record, "item.description") %></dt><dd class="media"><data class="media id <%=  _.get(record, "item.format") || "smart-decal" %>" value="<%= record.item.id %>"><%= record.item.display %></data></dd></dl>',
  //     {
  //       variable: "record",
  //     }
  //   );

  //   const _contact =
  //     '<% if(!!item.contact && !!item.contact.name) { %><li class="contact name note"><%= item.contact.name %></li><% } %><% if(!!item.contact && !!item.contact.email) { %><li class="contact email note"><a href="mailto:<%= item.contact.email %>"><%= item.contact.email %></a></li><% } %><% if(!!item.contact && !!(item.contact.phone || item.contact.tel)) { %><li class="contact tel note"><%= item.contact.phone || item.contact.tel %></li><% } %>';
  //   const _note = "<blockquote><p><%= item.text %></p></blockquote>";
  //   const _notes =
  //     '<ul class="notes">' +
  //     _contact +
  //     '<% if(!!item.notes && item.notes.length > 0) { %><% _.each(item.notes, function(item) { %><li class="user note">' +
  //     _note +
  //     "<% }); %><% } %></ul>";

  //   const _permit =
  //     '<li class="permit"><dl><% if(!!item.vehicle) { %><dt class="vehicle"></dt><dd class="vehicle"><data class="vehicle id" value="<%=  _.get(item, "vehicle.id", item.vehicle) %>"><%= _.get(item, "vehicle.display", item.vehicle) %></data></dd><% } %><% if(!!item.space) { %><dt class="space"></dt><dd class="space"><data class="space id" value="<%=  _.get(item, "space.id", item.space) %>"><%=  _.get(item, "space.display", item.space) %></data></dd><% } %><% if(!!item.tenant) { %><dt class="tenant <%=  _.get(item, "tenant.type", "") %>" data-type="<%=  _.get(item, "tenant.type", "") %>"></dt><dd class="tenant <%=  _.get(item, "tenant.type", "") %>" data-type="<%=  _.get(item, "tenant.type", "") %>"><data class="tenant id" value="<%=  _.get(item, "tenant.id", item.tenant) %>" data-type="<%=  _.get(item, "tenant.type", "") %>"><%=  _.get(item, "tenant.display", item.tenant) %></data></dd><% } %></dl>' +
  //     _notes +
  //     "</li>";

  //   const _permits = template(
  //     '<ul class="valid media permits <%= !record.valid || record.valid.length <= 0 ? "empty" : ""%>"><% if(!!record.valid) _.each(record.valid, function(item) { %>' +
  //       _permit +
  //       "<% }); %></ul>",
  //     {
  //       variable: "record",
  //       imports: {},
  //     }
  //   );

  //   const _footer = template(
  //     '<footer><time data-valid datetime="<%= record.generated %>"><%= dateFormat(new Date(record.generated)) %></time><p class="public">Only public info for permit and vehicle verification is displayed, no personal, private, or location information is available.</p></footer>',
  //     {
  //       variable: "record",
  //       imports: {
  //         dateFormat: generated,
  //       },
  //     }
  //   );

  //   const long = new Intl.DateTimeFormat("en-US", {
  //     year: "numeric",
  //     month: "short",
  //     weekday: "short",
  //     day: "numeric",
  //     hour: "numeric",
  //     minute: "numeric",
  //     timeZoneName: "short",
  //   }).format;

  //   function generated(date) {
  //     return long(date);
  //   }

  //   function view(record) {
  //     if (!record) {
  //       if (section) {
  //         while (section.firstChild) section.firstChild.remove();
  //         //section.removeAttribute("data-valid");
  //         section.removeAttribute("data-permits");
  //         section.setAttribute("data-record", "media");
  //       }
  //       root.removeAttribute("data-records");
  //       root.removeAttribute("data-valid");
  //       root.removeAttribute("data-permits");

  //       return record;
  //     }

  //     document.title = "Smart Decal";

  //     var admin = get(record, "admin", "");
  //     if (!!admin) {
  //       window.location.href = admin;

  //       return record;
  //     }

  //     if (!!record.attendant && !!record.location) {
  //       // self activation
  //       window.location.href =
  //         get(record, "location.attendant.url") +
  //         get(record, "location.id") +
  //         "/media/" +
  //         get(record, "item.id");
  //       return record;
  //     }

  //     if (!record.item) return record;

  //     root.removeAttribute("data-records");
  //     root.setAttribute("data-record", "media/" + get(record, "item.id", ""));
  //     section.setAttribute("data-record", "media/" + get(record, "item.id", ""));

  //     section.innerHTML =
  //       _header(record) + _template(record) + _permits(record) + _footer(record);
  //     section.setAttribute(
  //       "data-permits",
  //       get(record, "permits.length", 0) > 0 ? "true" : "false"
  //     );
  //     root.setAttribute(
  //       "data-permits",
  //       get(record, "permits.length", 0) > 0 ? "true" : "false"
  //     );

  //     section.setAttribute(
  //       "data-valid",
  //       get(record, "valid.length", 0) > 0 ? "true" : "false"
  //     );
  //     root.setAttribute(
  //       "data-valid",
  //       get(record, "valid.length", 0) > 0 ? "true" : "false"
  //     );

  //     return record;
  //   }

  //   async function media(media) {
  //     root.classList.add("ready");
  //     return view(await media);
  //   }

  //const root = document.documentElement;
</script>

<svelte:component
  this={types[media.usage]}
  {media}
  permits={$mediaPermits}
  policies={$mediaPolicies}
/>
