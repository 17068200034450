import { derived, type Readable } from "svelte/store";
import { fetchMedia } from "./api";
import { param } from "./params";
import type { Media, Permits, Permitted, Policies } from "../types";

export const mediaId = param("media");

const mediaStatus = derived<typeof mediaId, any>(mediaId, ($mediaId, set) => {
  if (!$mediaId) return set(null);

  fetchMedia($mediaId).then(set);
});

export const media = derived<[typeof mediaId, typeof mediaStatus], Media>(
  [mediaId, mediaStatus],
  ([$mediaId, $mediaStatus]) => {
    return (
      $mediaStatus?.items[$mediaId] ??
      $mediaStatus?.items[$mediaStatus.media.item] ??
      $mediaStatus?.media.item
    );
  }
);

export const mediaPermits = derived<
  [typeof media, typeof mediaStatus],
  Permitted
>([media, mediaStatus], ([$media, $data]) => {
  if (null == $media) return null;
  if (null == $data) return null;

  return {
    ...($data.permits["for"][$media.id] ?? {
      generated: $data.generated,
      count: 0,
      items: [],
    }),
    subject: $media,
  };
});
export const mediaPolicies = derived<
  [typeof media, typeof mediaStatus],
  Policies
>([media, mediaStatus], ([$media, $data]) => {
  if (null == $media) return null;
  if (null == $data) return null;
  return {
    ...($data.policies?.["for"]?.[$media.id] ?? {
      generated: $data.generated,
      count: 0,
      items: [],
    }),
    subject: $media,
  };
});
