import pick from "lodash-es/pick";

import { api, auth, client } from "./auth";
import { throttle } from "lodash-es";
import { format } from "date-fns";

export { api };

export function viewpoint(offset?: number) {
  return encodeURIComponent(
    format(new Date().getTime() + (offset || 0), "yyyy-MM-dd'T'HH:mm:ssxxx")
  );
}

const apiVersion = "v2";

export function base() {
  return api.settings.apiBase + apiVersion;
}

function formDataToURLSearchParams(formData) {
  return new URLSearchParams([...formData.entries()]); // what edge does this support?
}

export function coordsToURLSearchParams(coords) {
  return new URLSearchParams(
    Object.entries(
      pick(coords || {}, [
        "latitude",
        "longitude",
        "accuracy",
        "altitude",
        "altitudeAccuracy",
        "speed",
        "heading",
        "headingAccuracy",
      ])
    )
      .filter(([a, b]) => !!b)
      .map(([a, b]) => [a, b + ""])
  );
}

export async function fetchMedia(id: string) {
  if (!id) return null;

  const res = await fetch(`${base()}/media/${id}?viewpoint=${viewpoint()}`);
  const json = await res.json();

  // expand subjects & scopes
  for (const value of Object.values([
    ...Object.values(json.items),
    ...Object.values(json.media?.items || []),
    json.media?.item,
  ]) as any[]) {
    if (!value) continue;
    if (value.scope) value.property = resolveProperty(value.scope, json);
  }

  return json;
}

function resolvePolicy(item, state) {
  if (!item) return item;

  const items = state.items || state;

  item = items[item] || item;

  if (item.scope) item.property = resolveProperty(item.scope, state);

  for (const key of [
    "statistics",
    "pricing",
    "metered",
    "entry",
    "spaces",
    "units",
  ]) {
    //const value =
    item[key] =
      state[key]?.["for"]?.[item.id] || state[key]?.["for"]?.[item.subject];
    // if (value?.items)
    //   for (const [k2, v2] of Object.entries(value.items))
    //     value.items[k2] = state[v2] ?? state[k2] ?? v2;
  }
  for (const key of ["units", "spaces"]) {
    if (item[key])
      item[key] = Object.entries(item[key].items ?? item[key]).reduce(
        (result, [k, v]) => {
          if (!v) return result;
          result.items[k] = items[v as string] ?? items[k] ?? v;
          return result;
        },
        item[key].items ? item[key] : { items: {} }
      );
  }

  // if (item.units)
  //   item.units = Object.entries(item.units?.items ?? item.units).reduce(
  //     (result, [k, v]) => {
  //       if (!v) return result;
  //       result.items[k] = items[v] ?? items[k] ?? v;
  //       return result;
  //     },
  //     item.units.items ? item.units : { items: {} }
  //   );

  // item.statistics =
  //   get(state, ["statistics", "for", item.id]) ||
  //   get(state, ["statistics", "for", item.subject]);
  // item.pricing =
  //   get(state, ["pricing", "for", item.id]) ||
  //   get(state, ["pricing", "for", item.subject]);
  // item.metered =
  //   get(state, ["metered", "for", item.id]) ||
  //   get(state, ["metered", "for", item.subject]);
  // item.metered =
  //   get(state, ["metered", "for", item.id]) ||
  //   get(state, ["metered", "for", item.subject]);
  // for (let [id, v] of Object.entries(metersFromPolicy(item) || {})) {
  //   v = items[v] || v;
  //   if (!v || !v.principals) continue;
  //   for (const [id2, v2] of Object.entries(v.principals)) {
  //     v.principals[id2] = items[v2] || v2;
  //   }
  // }

  //item.property = resolveProperty(item.location, state);

  //console.log("policy=", item);

  return item;
}

function resolveProperty(item, state) {
  if (!item) return item;
  const items = state.items || state;
  item = items[item] || item;
  if (!item) return item;
  return resolveAddress(item, state);
}

function resolveAddress(item, state) {
  if (!item) return item;
  const items = state.items || state;
  item = items[item] || item;
  //console.log("address state=", state);
  if (item.address) item.address = items[item.address] || item.address;
  return item;
}

export function resolvePermits(values, items) {
  // values is the list of permits, items is the overall state

  if (!values || !items) return null;

  for (let [id, permit] of Object.entries(values) as any[]) {
    if (typeof permit == "string")
      permit = items[permit] || items[id] || permit;

    if (!permit || !permit.id) continue;

    values[id] = Object.assign(permit, {
      property: resolveProperty(items[permit.scope] || permit.scope, items),
      address: items[permit.address] || permit.address,
      policy: resolvePolicy(
        items[permit.issued.policy] ||
          items[permit.issued.issuer] ||
          permit.issued.issuer,
        items
      ),
      //vehicle: items[permit.vehicle] || permit.vehicle,
      //media: items[permit.media] || permit.media,
      //spaces: (permit.spaces || []).map(i => items[i] || i),
      spaces: Object.values(permit.spaces || []).reduce(
        (result: Record<string, any>, item: any) => {
          item = items[item] || item;
          result[item.id || item] = item;
          if (item.scope) item.property = resolveProperty(item.scope, items);
          return result;
        },
        {}
      ),
      subjects: Object.values(permit.subjects || []).reduce(
        (result: Record<string, any>, item: any) => {
          item = items[item] || item;
          result[item.id || item] = item;
          if (item.scope) item.property = resolveProperty(item.scope, items);
          return result;
        },
        {}
      ),
      //tenant: items[permit.tenant] || permit.tenant,
    });
  }

  return values;
}
