<script lang="ts">
  import type { File } from "../../types";
  import { thumbnail } from "../../util/filepreview";

  export let document: File = null;
</script>

{#if document}
  <figure class="file document">
    <a href={document.url} target="_blank"
      ><img src={thumbnail(document.url, 88, 88)} alt={document.title} /></a
    >
    <figcaption><a href={document.url}>{document.name}</a></figcaption>
  </figure>
{/if}
