import page from "page";
import { params } from "./params";
import { isArray } from "lodash-es";

// qs mechanics
page("*", function (ctx, next) {
  // don't run auth, we're running out of band
  const query = new URLSearchParams(ctx.querystring);
  ctx.query = [...query.keys()].reduce((result, k) => {
    var values = query.getAll(k);
    if (values?.length === 1) {
      result[k] = values[0];
    } else if (values?.length) {
      result[k] = values;
    }
    return result;
  }, {});
  //console.log(ctx.query);

  next();
});

export default page;

export const router = page;

export function state(ctx, next?) {
  params.set(Object.assign({}, ctx.params, ctx.query));
  if (!!next) next();
}

export function route(url) {
  page(url);
}

export function end(ctx) {
  state(ctx);
}

export function query(key, value?, path?) {
  if (arguments.length == 1 && typeof key == "object") {
    const qs = new URLSearchParams(window.location.search);
    for (const [k, v] of Object.entries(
      key as Record<string, string | string[]>
    )) {
      if (null != v && isArray(v)) {
        qs.delete(k);
        for (const valueItem of v as string[]) qs.append(k, valueItem);
      } else if (null != v) qs.set(k, v as string);
      else qs.delete(k);
    }
    let q = qs.toString();
    if (q) q = "?" + q;
    return route((path || window.location.pathname) + q);
  }

  const qs = new URLSearchParams(window.location.search);

  if (null != value && isArray(value)) {
    qs.delete(key);
    for (const valueItem of value) qs.append(key, valueItem);
  } else if (null != value) qs.set(key, value);
  else qs.delete(key);
  let q = qs.toString();
  if (q) q = "?" + q;
  route((path || window.location.pathname) + q);
}
