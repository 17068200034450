<script lang="ts">
  let classname = "info";

  export let message: string = "";
  export let title = "";
  export { classname as class };
</script>

{#if message}
  <figure role="alert" class={classname}>
    {#if title}
      <figcaption>{title}</figcaption>
    {/if}
    <p>{message}</p>
  </figure>
{/if}
