import { derived, writable } from "svelte/store";
import { param, params } from "./params";

export const camera = writable({});
export const view = param("view");
export const scan = derived(
  params,
  ($params) => null != $params?.scan || "scan" === $params.view
);

export const minimal = derived(
  param("minimal"),
  ($minimal) => $minimal == "true"
);

//view.subscribe($view => console.log("view=", $view));
