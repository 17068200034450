<script context="module" lang="ts">
  import identity from "lodash-es/identity";
</script>

<script lang="ts">
  import RecordExtras from "./RecordExtras.svelte";
  import RecordLink from "./RecordLink.svelte";
  import RecordSpaceCovered from "./RecordSpaceCovered.svelte";

  export let url = false;
  export let item;
</script>

{#if item}
  <RecordLink {item} {url}>
    <data
      class={["id", item.type, item._type, item.format]
        .filter(identity)
        .join(" ")}
      value={item.id || item.key}
      >{item.display}<slot
        ><RecordSpaceCovered {item} label={false} /><RecordExtras
          {item}
        /></slot
      ></data
    ></RecordLink
  >
{/if}
