<script lang="ts">
  import { scan, view } from "./util/uistores";
  import { mediaId, media } from "./util/recordstores";
  import MediaSection from "./components/sections/MediaSection.svelte";
  import Scan from "./components/sections/ScanSection.svelte";
  import Loading from "./components/util/Loading.svelte";
  import IndexSection from "./components/sections/IndexSection.svelte";
  import ScanSection from "./components/sections/ScanSection.svelte";

  $: console.log("mediaId change=", $mediaId);
</script>

{#if $mediaId}
  <!-- <Loading /> -->
  {#if $media}
    <MediaSection media={$media} />
  {:else}
    <Loading />
  {/if}
{:else if "scan" === $view}
  <ScanSection />
{:else}
  <IndexSection />
  <!-- <footer>
    {#if $scan}
      <Scanner />
      <button class="scan" type="button" on:click={() => query("scan", null)}
        >Close Scanner</button
      >
    {:else}
      <button class="scan" type="button" on:click={() => query("scan", "true")}
        >Scan Barcode</button
      >
    {/if}
  </footer> -->
{/if}
