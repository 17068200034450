<script>
  import Scanner from "../util/Scanner.svelte";

  // function onurl(e) {
  //   var url = e.detail;

  //   //console.log("url=", url);
  //   if (url != null) {
  //     const tagData = infotag.parse(url);
  //     //console.log("tagdata=", tagData);
  //     return (window.location.href = url);
  //   }
  // }
</script>

<main class="scan">
  <header>
    <h1>Scan Barcode</h1>
  </header>
  <Scanner />
</main>
