<script lang="ts">
</script>

<main class="start">
  <header>
    <h1>Hi there!</h1>
  </header>

  <nav>
    <!-- <header>
      <p>Scan barcode with the scanner below or use your phone's camera</p>
    </header> -->
    <ul>
      <li>
        <a href="/scan">Scan Barcode</a>
      </li>
    </ul>
  </nav>
</main>
