<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { infotag } from "@parkingboss/utils";
  import UrlBarcodeScanner from "./UrlBarcodeScanner.svelte";

  export let selected = null;
  export let property = null; // property gates observation flag?

  const dispatch = createEventDispatcher();

  function select(value) {
    //console.log("value=", value);

    selected = value;
    if (selected) dispatch("infotag", value);
  }

  function onbarcode(e) {
    const url = e.detail;

    console.log("url=", url);

    const info = infotag.parse(url);
    console.log("infotag=", info);
    if (!info) return;

    const value = {
      type: info.format,
      id: info.id,
      key: info.serial,
      url: url,
    };
    return select(value);
  }
</script>

<UrlBarcodeScanner on:url={onbarcode} />
