<script lang="ts">
  import type { Policy } from "../../types";
  import PolicyInfoOnlyDetails from "./PolicyInfoOnlyDetails.svelte";

  export let policy: Policy = null;
</script>

{#if true === policy?.issue?.enabled}
  <!-- <p>Real Policy</p> -->
{:else if policy}
  <PolicyInfoOnlyDetails {policy} />
{/if}
