<script lang="ts">
  import { parseISO } from "date-fns";
  import { format } from "date-fns-tz";
  import type { Media, Permits, Policies } from "../types";
  import MediaRecord from "./MediaRecord.svelte";
  import MediaStatusHeader from "./MediaStatusHeader.svelte";
  import PropertyHeader from "./util/PropertyHeader.svelte";
  import Help from "./util/Help.svelte";
  import Photo from "./util/Photo.svelte";
  import RecordItem from "./util/RecordItem.svelte";
  import Warning from "./util/Warning.svelte";
  export let media: Media;
  export let permits: Permits;
  export let policies: Policies;
  const property = media.property;
  const active = Object.values(permits.items).filter((p) => !p.valid.expired);
</script>

<main class="vehicle media record">
  <MediaStatusHeader {media} {permits} />
  {#if property}
    <PropertyHeader {property} />
  {/if}
  <MediaRecord {media} />
  <section class="permits">
    <ul>
      {#each Object.values(active) as permit}
        <li>
          <dl>
            {#if permit.vehicle}
              <dt>Vehicle</dt>
              <dd><RecordItem item={permit.vehicle} /></dd>
            {/if}
            {#if permit.space}
              <dt>parked in</dt>
              <dd><RecordItem item={permit.space} /></dd>
            {/if}
          </dl>
        </li>
      {/each}
    </ul>
    <footer>
      <p>
        <time datetime={permits.generated}
          ><dfn>Synced </dfn><abbr
            title={format(
              parseISO(permits.generated),
              "h:mm a EEE MMM d yyyy zzz"
            )}
          />{format(
            parseISO(permits.generated),
            "h:mm a EEE MMM d yyyy zzz"
          )}</time
        >
      </p>
    </footer>
  </section>

  <nav class="policies">
    {#if policies.count}
      <ul>
        {#each Object.values(policies.items) as policy}
          <li>
            <a
              href={new URL(
                `/${policy.scope}/permits/new?policy=${policy.policy}&media=${media.key}`,
                policy.url
              ).toString()}>{policy.title}</a
            >
          </li>
        {/each}
      </ul>
    {:else}
      <Help
        message="Please contact your property or parking management with questions, concerns, or changes"
      />
    {/if}
  </nav>

  <footer>
    <p>
      Once activated, only public info for permit and vehicle verification is
      displayed, no personal, private, or location information is available.
    </p>
    <p>
      {media.title} must be active and valid for the parked vehicle and location.
      Use of an inactive, revoked, or invalid {media.title} may result in citation,
      booting, immobilization, or towing at owner's expense.
    </p>
  </footer>
</main>
